import React from "react"
import Layout from "../Layout"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Figure from "../Figure"
import YouTube from "../Youtube"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import ImageBuilder from "../ImageBuilder"
import Seo from "../utils/Seo"
import BackToTop from "../utils/BackToTop"

export const query = graphql`
  query ($slug: String!) {
    page: allSanityMls(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        title
        portraitimage {
          alt
          asset {
            gatsbyImageData(width: 600, height: 700)
          }
        }
        landscapeimage {
          alt
          asset {
            landscape: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        productcontent {
          _key
          _type
          _rawCol
        }
        _rawGallerybuilder(resolveReferences: { maxDepth: 10 })
        gallerybuilder {
          ... on SanityGallery {
            images {
              asset {
                gatsbyImageData(width: 600, height: 700)
              }
            }
          }
          ... on SanitySingleImage {
            asset {
              gatsbyImageData
            }
          }
        }
        meta {
          title
          description
        }
      }
    }
  }
`

const Collection = props => {
  const serializer = {
    types: {
      singleImage: Figure,
      videoEmbed: YouTube,
    },
  }

  const page = props.data.page.nodes[0]

  return (
    <Layout>
      <Seo
        title={page.meta && page.meta.title ? page.meta.title : page.title}
        description={
          page.meta && page.meta.description ? page.meta.description : ""
        }
      />
      <GatsbyImage
        className="mb-10 w-full"
        image={page.landscapeimage.asset.landscape}
        alt={page.landscapeimage.alt}
      />
      <div className="md:grid grid-cols-2 gap-10">
        <div className="mb-10 md:mb-0">
          <BlockContent
            className="pagebuilder border-l pl-5"
            blocks={page.productcontent._rawCol}
            serializers={serializer}
          />
          <a className="button" href="mailto:info@marielouisesjogren.com">
            Email to purchase
          </a>
        </div>
        <GatsbyImage
          className="md:mb-2"
          image={page.portraitimage.asset.gatsbyImageData}
          alt={page.portraitimage.alt}
        />
      </div>
      <ImageBuilder imageLayoutbuilder={page.gallerybuilder} />
      <BackToTop />
    </Layout>
  )
}

export default Collection
